import * as React from "react"
import { useTheme } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import background from "../images/menu.png"
import Text from "../components/text/text"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@material-ui/core/Container"
import menu from "../components/menu/menu.json"
import "./index.css"

const IndexPage = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <div style={{}}>
      <Grid
        container
        style={{ backgroundImage: `url(${background})`, height: "600px" }}
      >
        <Container
          maxWidth="lg"
          style={{
            display: "flex",
          }}
        >
          <Grid
            item
            lg={12}
            xl={12}
            style={{
              zIndex: 1,
              paddingTop: smDown ? "50ch" : "46ch",
            }}
          >
            <Text variant={"h1"} style={{ color: "white", cursor: "default" }}>
              {" "}
              Menu
            </Text>
          </Grid>
        </Container>
      </Grid>
      <Container
        maxWidth="lg"
        style={{
          display: "flex",
        }}
      >
        {smDown ? (
          <div className="horizantal_slider">
            <div className="slider_container">
              <div className="item">
                <Text varient={"body1"}>
                  <a
                    href="#signature"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {" "}
                    Istanbul Signature{" "}
                  </a>
                </Text>
              </div>
              <div className="item">
                <Text varient={"body1"}>
                  <a
                    href="#wraps"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {" "}
                    Wraps{" "}
                  </a>
                </Text>
              </div>
              <div className="item">
                <Text varient={"body1"}>
                  <a
                    href="#arabic-plates"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {" "}
                    Arabic plates{" "}
                  </a>
                </Text>
              </div>
              <div className="item">
                <Text varient={"body1"}>
                  <a
                    href="#plates"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {" "}
                    Plates{" "}
                  </a>
                </Text>
              </div>
              <div className="item">
                <Text varient={"body1"}>
                  <a
                    href="#salads"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {" "}
                    Sides & Salads{" "}
                  </a>
                </Text>
              </div>
              <div className="item">
                <Text varient={"body1"}>
                  <a
                    href="#beverages"
                    style={{ color: "black", textDecoration: "none" }}
                  >
                    {" "}
                    Beverages{" "}
                  </a>
                </Text>
              </div>
            </div>
          </div>
        ) : (
          <Grid
            container
            spacing={2}
            direction="row"
            style={{ paddingTop: "60px" }}
          >
            <Grid item lg={2}>
              <Text
                varient={"body1"}
                style={{
                  fontSize: "14px",
                  paddingBottom: "20px",
                  textAlign: "center",
                  cursor: "pointer",

                  "&:hover": {
                    textDecoration: "underline",
                    textDerorationColor: "red",
                  },
                }}
              >
                <a
                  href="#signature"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {" "}
                  Istanbul Signature{" "}
                </a>
              </Text>
            </Grid>

            <Grid item lg={2} style={{ paddingLeft: "43px" }}>
              <Text
                varient={"body1"}
                style={{
                  fontSize: "14px",
                  paddingBottom: "20px",
                  textAlign: "center",
                  cursor: "pointer",

                  "&:hover": {
                    textDecoration: "underline",
                    textDerorationColor: "red",
                  },
                }}
              >
                <a
                  href="#wraps"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Wraps
                </a>
              </Text>
            </Grid>
            <Grid item lg={2}>
              <Text
                varient={"body1"}
                style={{
                  fontSize: "14px",
                  paddingBottom: "20px",
                  textAlign: "center",
                  cursor: "pointer",

                  "&:hover": {
                    textDecoration: "underline",
                    textDerorationColor: "red",
                  },
                }}
              >
                <a
                  href="#arabic-plates"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Arabic plate
                </a>
              </Text>
            </Grid>
            <Grid item lg={2}>
              <Text
                varient={"body1"}
                style={{
                  fontSize: "14px",
                  paddingBottom: "20px",
                  textAlign: "center",
                  cursor: "pointer",

                  "&:hover": {
                    textDecoration: "underline",
                    textDerorationColor: "red",
                  },
                }}
              >
                <a
                  href="#plates"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {" "}
                  Plates
                </a>
              </Text>
            </Grid>
            <Grid item lg={2}>
              <Text
                varient={"body1"}
                style={{
                  fontSize: "14px",
                  paddingBottom: "20px",
                  textAlign: "center",
                  cursor: "pointer",

                  "&:hover": {
                    textDecoration: "underline",
                    textDerorationColor: "red",
                  },
                }}
              >
                <a
                  href="#salads"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  {" "}
                  Sides & Salads
                </a>
              </Text>
            </Grid>
            <Grid item lg={2}>
              <Text
                varient={"body1"}
                style={{
                  fontSize: "14px",
                  paddingBottom: "20px",
                  textAlign: "center",
                  cursor: "pointer",

                  "&:hover": {
                    textDecoration: "underline",
                    textDerorationColor: "red",
                  },
                }}
              >
                {" "}
                <a
                  href="#beverages"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  Beverages
                </a>
              </Text>
            </Grid>
          </Grid>
        )}
      </Container>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            lg={12}
            xl={12}
            sm={12}
            xs={12}
            md={12}
            id="signature"
            style={{
              paddingTop: "60px",
              paddingBottom: "20px",
              borderBottom: "3px solid #A72D3F",
            }}
          >
            <Text variant={"h4"} style={{ fontWeight: "bold" }}>
              Istanbul Signature
            </Text>
          </Grid>
        </Grid>
      </Container>
      {menu.map(c => {
        return (
          <>
            {c.type === "IstanbulSignature" && (
              <Container maxWidth="lg">
                <Grid container>
                  <Grid item lg={8} xl={8} style={{}}>
                    <Text
                      variant={"h5"}
                      style={{ fontWeight: "bold", paddingTop: "20px" }}
                    >
                      {c.subtitle}
                    </Text>

                    <Text
                      variant={"body1"}
                      style={{
                        color: "gray",
                        paddingTop: "20px",
                        paddingBottom: "18px",
                      }}
                    >
                      {c.description}
                    </Text>
                    <Text
                      variant={"h6"}
                      style={{ paddingBottom: "25px", fontWeight: "bold" }}
                    >
                      {c.price}
                    </Text>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xl={4}
                    style={{
                      paddingLeft: smDown ? "0px" : "150px",
                      paddingTop: "20px",
                    }}
                  >
                    <img src={c.image} width="220px" alt="none "></img>
                  </Grid>
                  <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                    <hr
                      style={{
                        color: "",
                        height: "1px  ",
                        paddingTop: "1px",
                        borderWidth: "1px",
                        backgroundColor: "#c0c6c9",
                      }}
                    />{" "}
                  </Grid>
                </Grid>
              </Container>
            )}
          </>
        )
      })}
      <Grid container style={{ backgroundColor: "#EAEAEA" }}>
        <Container maxWidth="lg">
          <Grid
            item
            lg={12}
            xl={12}
            md={12}
            sm={12}
            xs={12}
            id="wraps"
            style={{ paddingTop: "60px", paddingBottom: "20px" }}
          >
            <Text variant={"h4"} style={{ fontWeight: "bold" }}>
              Wraps
            </Text>
            <hr
              style={{
                color: "",
                height: "1px  ",
                paddingTop: "2px",
                borderWidth: "1px",
                backgroundColor: "#A72D3F",
                marginBottom: "30px",
                marginTop: "30px",
              }}
            />{" "}
          </Grid>
        </Container>
      </Grid>
      {menu.map(c => {
        return (
          <>
            {c.type === "wraps" && (
              <Grid container style={{ backgroundColor: "#EAEAEA" }}>
                <Container maxWidth="lg">
                  <Grid container>
                    <Grid item lg={8} xl={8}>
                      <Text variant={"h5"} style={{ fontWeight: "bold" }}>
                        {c.subtitle}
                      </Text>

                      <Text
                        variant={"body1"}
                        style={{
                          color: "gray",
                          paddingTop: "20px",
                          paddingBottom: "18px",
                        }}
                      >
                        {c.description}
                      </Text>
                      <Text
                        variant={"h6"}
                        style={{ paddingBottom: "25px", fontWeight: "bold" }}
                      >
                        {c.price}
                      </Text>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xl={4}
                      style={{ paddingLeft: smDown ? "0px" : "150px" }}
                    >
                      <img src={c.image} width="220px" alt="none "></img>
                    </Grid>
                    <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                      <hr
                        style={{
                          color: "",
                          height: "1px  ",
                          paddingTop: "1px",
                          borderWidth: "1px",
                          backgroundColor: "#c0c6c9",
                        }}
                      />{" "}
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            )}
          </>
        )
      })}
      <Container maxWidth="lg">
        <Grid
          item
          lg={12}
          xl={12}
          sm={12}
          xs={12}
          id="arabic-plates"
          style={{ paddingTop: "60px", paddingBottom: "20px" }}
        >
          <Text variant={"h4"} style={{ fontWeight: "bold" }}>
            Arabic plate{" "}
          </Text>
          <hr
            style={{
              color: "",
              height: "1px  ",
              paddingTop: "2px",
              borderWidth: "1px",
              backgroundColor: "#A72D3F",
              marginBottom: "30px",
              marginTop: "30px",
            }}
          />{" "}
        </Grid>
      </Container>
      {menu.map(c => {
        return (
          <>
            {c.type === "arabicplates" && (
              <Container maxWidth="lg">
                <Grid container>
                  <Grid item lg={8} xl={8}>
                    <Text variant={"h5"} style={{ fontWeight: "bold" }}>
                      {c.subtitle}
                    </Text>

                    <Text
                      variant={"body1"}
                      style={{
                        color: "gray",
                        paddingTop: "20px",
                        paddingBottom: "18px",
                      }}
                    >
                      {c.description}
                    </Text>
                    <Text
                      variant={"h6"}
                      style={{ paddingBottom: "25px", fontWeight: "bold" }}
                    >
                      {c.price}
                    </Text>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    xl={4}
                    sm={12}
                    xs={12}
                    md={12}
                    style={{ paddingLeft: smDown ? "0px" : "150px" }}
                  >
                    <img src={c.image} width="220px" alt="none "></img>
                  </Grid>
                  <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                    <hr
                      style={{
                        color: "",
                        height: "1px  ",
                        paddingTop: "1px",
                        borderWidth: "1px",
                        backgroundColor: "#c0c6c9",
                      }}
                    />{" "}
                  </Grid>
                </Grid>
              </Container>
            )}
          </>
        )
      })}
      <Grid container style={{ backgroundColor: "#EAEAEA" }}>
        <Container maxWidth="lg">
          <Grid
            item
            lg={12}
            xl={12}
            md={12}
            sm={12}
            xs={12}
            id="plates"
            style={{ paddingTop: "60px", paddingBottom: "20px" }}
          >
            <Text variant={"h4"} style={{ fontWeight: "bold" }}>
              Plates
            </Text>
            <hr
              style={{
                color: "",
                height: "1px  ",
                paddingTop: "2px",
                borderWidth: "1px",
                backgroundColor: "#A72D3F",
                marginBottom: "30px",
                marginTop: "30px",
              }}
            />{" "}
          </Grid>
        </Container>
      </Grid>
      {menu.map(c => {
        return (
          <>
            {c.type === "plates" && (
              <Grid container style={{ backgroundColor: "#EAEAEA" }}>
                <Container maxWidth="lg">
                  <Grid container>
                    <Grid item lg={8} xl={8}>
                      <Text variant={"h5"} style={{ fontWeight: "bold" }}>
                        {c.subtitle}
                      </Text>

                      <Text
                        variant={"body1"}
                        style={{
                          color: "gray",
                          paddingTop: "20px",
                          paddingBottom: "18px",
                        }}
                      >
                        {c.description}
                      </Text>
                      <Text
                        variant={"h6"}
                        style={{ paddingBottom: "25px", fontWeight: "bold" }}
                      >
                        {c.price}
                      </Text>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={12}
                      md={12}
                      style={{ paddingLeft: smDown ? "0px" : "150px" }}
                    >
                      <img src={c.image} width="220px" alt="none "></img>
                    </Grid>
                    <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                      <hr
                        style={{
                          color: "",
                          height: "1px  ",
                          paddingTop: "1px",
                          borderWidth: "1px",
                          backgroundColor: "#c0c6c9",
                        }}
                      />{" "}
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            )}
          </>
        )
      })}

      <Grid container style={{}}>
        <Container maxWidth="lg">
          <Grid
            item
            lg={12}
            xl={12}
            md={12}
            sm={12}
            xs={12}
            id="salads"
            style={{ paddingTop: "60px", paddingBottom: "20px" }}
          >
            <Text variant={"h4"} style={{ fontWeight: "bold" }}>
              Slides & Salads
            </Text>
            <hr
              style={{
                color: "",
                height: "1px  ",
                paddingTop: "2px",
                borderWidth: "1px",
                backgroundColor: "#A72D3F",
                marginBottom: "30px",
                marginTop: "30px",
              }}
            />{" "}
          </Grid>
        </Container>
      </Grid>
      {menu.map(c => {
        return (
          <>
            {c.type === "salads" && (
              <Grid container style={{}}>
                <Container maxWidth="lg">
                  <Grid container>
                    <Grid item lg={8} xl={8}>
                      <Text variant={"h5"} style={{ fontWeight: "bold" }}>
                        {c.subtitle}
                      </Text>

                      <Text
                        variant={"body1"}
                        style={{
                          color: "gray",
                          paddingTop: "20px",
                          paddingBottom: "18px",
                        }}
                      >
                        {c.description}
                      </Text>
                      <Text
                        variant={"h6"}
                        style={{ paddingBottom: "25px", fontWeight: "bold" }}
                      >
                        {c.price}
                      </Text>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={12}
                      md={12}
                      style={{ paddingLeft: smDown ? "0px" : "150px" }}
                    >
                      <img src={c.image} width="220px" alt="none "></img>
                    </Grid>
                    <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                      <hr
                        style={{
                          color: "",
                          height: "1px  ",
                          paddingTop: "1px",
                          borderWidth: "1px",
                          backgroundColor: "#c0c6c9",
                        }}
                      />{" "}
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            )}
          </>
        )
      })}
      <Grid container style={{ backgroundColor: "#EAEAEA" }}>
        <Container maxWidth="lg">
          <Grid
            item
            lg={12}
            xl={12}
            md={12}
            sm={12}
            xs={12}
            id="beverages"
            style={{ paddingTop: "60px", paddingBottom: "20px" }}
          >
            <Text variant={"h4"} style={{ fontWeight: "bold" }}>
              Beverages
            </Text>
            <hr
              style={{
                color: "",
                height: "1px  ",
                paddingTop: "2px",
                borderWidth: "1px",
                backgroundColor: "#A72D3F",
                marginBottom: "30px",
                marginTop: "30px",
              }}
            />{" "}
          </Grid>
        </Container>
      </Grid>
      {menu.map(c => {
        return (
          <>
            {c.type === "beverages" && (
              <Grid container style={{ backgroundColor: "#EAEAEA" }}>
                <Container maxWidth="lg">
                  <Grid container>
                    <Grid item lg={8} xl={8}>
                      <Text variant={"h5"} style={{ fontWeight: "bold" }}>
                        {c.subtitle}
                      </Text>

                      <Text
                        variant={"body1"}
                        style={{
                          color: "gray",
                          paddingTop: "20px",
                          paddingBottom: "18px",
                        }}
                      >
                        {c.description}
                      </Text>
                      <Text
                        variant={"h6"}
                        style={{ paddingBottom: "25px", fontWeight: "bold" }}
                      >
                        {c.price}
                      </Text>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      xl={4}
                      sm={12}
                      xs={12}
                      md={12}
                      style={{ paddingLeft: smDown ? "0px" : "150px" }}
                    >
                      <img src={c.image} width="220px" alt="none "></img>
                    </Grid>
                    <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                      <hr
                        style={{
                          color: "",
                          height: "1px  ",
                          paddingTop: "1px",
                          borderWidth: "1px",
                          backgroundColor: "#c0c6c9",
                        }}
                      />{" "}
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            )}
          </>
        )
      })}
    </div>
  )
}

export default IndexPage
